import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export interface UserReturn {
  url?: string;
  status: number;
  sesame_token?: string;
  message?: string;
  error_code?: number;
}
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  readonly url = environment.iframe;
  logged = false;
  redirect = null;

  // app = 201; // DV PAss 4 merchants
  // app = 206; // DV Contact
  app = 207; // DV Live stat

  set token(value) {
    localStorage.setItem('token', value);
  }
  get token(): string {
    return localStorage.getItem('token');
  }

  constructor(private http: HttpClient, private router: Router) {}

  login(
    {
      login,
      password,
      sesame_token,
    }: {
      login: string;
      password: string;
      sesame_token: string;
    },
    remember: boolean
  ): Observable<UserReturn> {
    const body = {
      app: this.app,
      login,
      password,
      sesame_token,
    };
    return this.http.post<UserReturn>(this.url + '/login', JSON.stringify(body)).pipe(
      map((res) => {
        if (res.status === 200) {
          this.router.navigate(['/home']);
          this.logged = true;
          this.redirect = res.url;
          if (remember) {
            this.token = res.sesame_token;
          }
        }
        return res;
      })
    );
  }
}
